<template>
	<v-row class="mt-3">
		<v-col md="12" class="text-right pt-0">
			<v-btn v-on:click="dialog = true" class="white--text mr-2" depressed color="blue darken-4" tile>
				Add Contact Person
			</v-btn>
		</v-col>
		<v-col md="12">
			<v-simple-table class="bt-table" :class="className">
				<thead>
					<tr>
						<th class="simple-table-th">#</th>
						<th class="simple-table-th">Salutation</th>
						<th class="simple-table-th">First Name</th>
						<th class="simple-table-th">Last Name</th>
						<th class="simple-table-th">Display Name</th>
						<th class="simple-table-th">Phone Number</th>
						<th class="simple-table-th">Email</th>
						<th class="simple-table-th">Designation</th>
						<th class="simple-table-th"></th>
					</tr>
				</thead>
				<tbody v-if="contactPersons.length">
					<tr v-for="(row, index) in contactPersons" :key="index">
						<td class="cursor-default simple-table-td">
							{{ index + 1 }}
						</td>
						<td class="cursor-default simple-table-td text-capitalize">{{ row.salutation }}.</td>
						<td class="cursor-default simple-table-td">
							{{ row.first_name }}
						</td>
						<td class="cursor-default simple-table-td">
							{{ row.last_name }}
						</td>
						<td class="cursor-default simple-table-td">
							{{ row.display_name }}
						</td>
						<td class="cursor-default simple-table-td">
							{{ row.mobile }}
						</td>
						<td class="cursor-default simple-table-td">
							{{ row.email }}
						</td>
						<td class="cursor-default simple-table-td">
							{{ row.designation }}
						</td>
						<td class="simple-table-td">
							<template v-if="index > 0">
								<v-btn
									class="mx-2"
									color="red lighten-1"
									dark
									fab
									x-small
									v-on:click="removeContactPerson(index, row)"
								>
									<v-icon small dark> mdi-trash-can-outline</v-icon>
								</v-btn>
							</template>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="8">
							<p class="my-4 row-not-found text-center font-weight-500 font-size-16">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no contact person at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</v-col>
		<CreateContactPerson
			v-model="contactPersons"
			:person-dialog="dialog"
			v-on:close="dialog = false"
		></CreateContactPerson>
	</v-row>
</template>

<script>
import CreateContactPerson from "@/view/components/Create-Contact-Person";
import { cloneDeep } from "lodash";

export default {
	name: "contact-person",
	title: "Customer",
	props: {
		detail: {
			type: Object,
			default: function () {
				return {};
			},
		},
		customer: {
			type: Object,
			default: function () {
				return {};
			},
		},
		defaultContactPersons: {
			type: Array,
			default: function () {
				return [];
			},
		},
		isDialog: {
			type: Boolean,
			default: false,
		},
		person: {
			type: Number,
			default: 0,
		},
		updateContact: {
			type: Boolean,
			default: false,
		},
		className: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			dialog: false,
			contactPerson: {
				id: null,
				title: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				phone: null,
				designation: null,
				default: 0,
			},
			contactPersons: [],
		};
	},
	watch: {
		contactPersons: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					let result = cloneDeep(param);
					this.$emit("saveContactPerson", result);
				}
			},
		},
		detail: {
			deep: true,
			immediate: true,
			handler() {
				this.makeDefaultEntry();
			},
		},
	},
	methods: {
		appendDisplayName() {
			const result = [];
			const { first_name, last_name } = this.contactPerson;
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			this.contactPerson.display_name = result.join(" ");
		},
		appendDialogDisplayName(index) {
			const result = [];
			const { first_name, last_name } = this.contactPersons[index];
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			this.contactPersons[index].display_name = result.join(" ");
		},
		makeDefaultEntry() {
			const _this = this;
			try {
				if (_this.updateContact) {
					_this.contactPersons = _this.defaultContactPersons;
					console.log(_this.contactPersons, "hi");
					console.log({ contactPersons: _this.contactPersons });
					console.log({ defaultContactPersons: _this.defaultContactPersons });
				} else {
					if (_this.detail.display_name) {
						console.log("189");
						let firstEntry = {
							id: null,
							uuid: null,
							salutation: "mr",
							first_name: _this.detail.first_name ? _this.detail.first_name : null,
							last_name: _this.detail.last_name ? _this.detail.last_name : null,
							display_name: _this.detail.display_name,
							designation: _this.detail.designation,
							email: _this.detail.email,
							mobile: _this.detail.phone_no,
						};
						_this.contactPersons.splice(0, 1, firstEntry);
					}
				}
			} catch (error) {
				_this.contactPersons.splice(0, 1);
				_this.logError(error);
			}
		},
		createDisplayName() {
			let firstname = "";
			if (this.detail.first_name) {
				firstname = this.detail.first_name.toLowerCase();
			}
			let lastname = "";
			if (this.detail.last_name) {
				lastname = this.detail.last_name.toLowerCase();
			}
			return firstname + lastname;
		},
		pushContactPerson() {
			this.contactPersons.push({
				id: null,
				title: "mr",
				menu: false,
				first_name: null,
				last_name: null,
				display_name: null,
				primary_email: null,
				emails: [],
				primary_phone: null,
				will_notified: 1,
				designation: null,
				position: null,
				default: 0,
			});
		},
		removeContactPerson(index, data) {
			if (data.id) {
				alert(data.id);
			} else {
				this.contactPersons.splice(index, 1);
			}
		},
	},
	components: {
		CreateContactPerson,
	},
	mounted() {
		this.makeDefaultEntry();
		if (this.isDialog) {
			if (this.contactPersons.length <= 0) {
				this.contactPersons.push({
					id: null,
					title: "mr",
					menu: false,
					first_name: null,
					last_name: null,
					display_name: null,
					primary_email: null,
					primary_phone: null,
					emails: [],
					will_notified: 1,
					position: null,
					designation: null,
					default: 0,
				});
			}
		}
		this.makeDefaultEntry();
		if (this.person > 0) {
			this.contactPersons = [this.getPersonById(this.customer, this.person)];
		}
	},
};
</script>
